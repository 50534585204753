import * as React from 'react'

const Article = ({ children }) => {
  return (
    <article className='container-fluid'>
      <div className='row justify-content-center py-5'>
        <div className='col-md-10 col-lg-8 col-xl-6'>{children}</div>
      </div>
    </article>
  )
}

export default Article
